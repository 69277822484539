import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import Web3 from 'web3';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import LinkNav from './components/LinkNav';
import { WalletContext } from './providers/WalletProvider';
import { ownerOf, tokenURI, verifyTokenFileSigner, signFileSignatureToken } from './helpers/web3';

import 'react-lazy-load-image-component/src/effects/opacity.css';


export default function Verify() {
    let navigate = useNavigate();
    const { config, resetApp, onConnect } = useContext(WalletContext);
    const [tokenId, setTokenId] = useState('');
    const [owner, setOwner] = useState('');
    const [metadata, setMetadata] = useState({});
    const [documentId, setDocumentId] = useState('');
    const [signature, setSignature] = useState('');

    const startVerify = async () => {
        try {
            if (tokenId > -1 && tokenId !== '') {
                const _owner = await ownerOf(tokenId, config.chainId, config.web3);
                console.log('V OWNER', owner);
                setOwner(_owner);
    
                const _tokenURI = await tokenURI(tokenId, config.chainId, config.web3);
                console.log('V TOKEN URI', _tokenURI);
    
                const res = await axios.get(_tokenURI);
                console.log('V METADATA', res.data);
                setMetadata(res.data);
    
                if (res.data && res.data.attributes) {
                    const index = res.data.attributes.map((a) => a.trait_type).indexOf('Validation ID');
                    setDocumentId(res.data.attributes[index].value);
                }

                window.scrollTo(0, 0);
            }
        }
        catch (err) {
            console.error(err);
        }
    };

    const startFileSign = async () => {
        try {
            const _signature = await signFileSignatureToken(metadata.name, metadata, documentId, config.chainId, config.web3, config.ethers);
            console.log('V SIGNATURE', _signature);
            setSignature(_signature);

            const _isSigner = await verifyTokenFileSigner(metadata.name, metadata, documentId, _signature, config.address, config.chainId, config.web3);
            console.log('V IS SIGNER', _isSigner);

            window.scrollTo(0, 0);
        }
        catch (err) {
            console.error(err);
        }
    };

    return (
        <div>
            <LinkNav address={config.address} resetApp={resetApp} chainId={config.chainId} />
            {
                config.address === '' ? 
                    <Container style={{marginTop: '80px'}}>
                        <p className="m-0 top-headline" style={{paddingTop: '100px', paddingBottom: '20px'}}>{process.env.REACT_APP_SIMPLE_NAME} On Meta Collection</p>
                        <h1 className="headline" style={{paddingBottom: '20px'}}>Connect Wallet</h1>
                        <div className="text-center">
                            <Button className="m-2 btn-custom rounded-0" onClick={onConnect}>
                                Continue
                            </Button>
                        </div>
                    </Container>
                :
                    owner === '' ? 
                        <>
                            <div className="hero-custom" style={{backgroundColor: '#EBFDFF', marginTop: '80px', paddingBottom: '80px'}}>
                                <Container>
                                    <p className="m-0 top-headline" style={{paddingTop: '100px', paddingBottom: '20px'}}>{process.env.REACT_APP_SIMPLE_NAME} On Meta Collection</p>
                                    <h1 className="headline" style={{paddingBottom: '20px'}}>Verify Ownership</h1>

                                    <p className="text-center px-4 my-0 mx-auto fw-bold" style={{maxWidth: '600px'}}>Please verify the ownership of this token by entering your Token ID in the space below.</p>

                                    <p className="text-center px-4 mt-4 mb-0 mx-auto fw-bold" style={{maxWidth: '600px'}}>Verification will be necessary in order to receive any physical pieces you may be entitled to.</p>
                                </Container>
                            </div>

                            <Container className="pb-4">
                                <Row className="justify-content-center">
                                    <Col sm={12} md={6} className="d-flex align-items-center justify-content-center p-5 order-1 order-md-0">
                                        <div className="text-center" style={{maxWidth: '100%'}}>
                                            <h2 className="text-start second-headline">Token ID</h2>
                                            <Form>
                                                <Form.Group className="mb-3 text-dark text-start" controlId="formTokenId">
                                                    <Form.Control type="number" placeholder="" value={tokenId} onChange={(e) => setTokenId(e.target.value)} />
                                                    <Form.Text className="text-muted fst-italic text-start">
                                                        The token identifier can be found under <Link className="text-muted" to="/my-tokens">My Tokens</Link>.
                                                    </Form.Text>
                                                </Form.Group>
                                            </Form>
                                            <div className="mt-5">
                                                <Button className="btn-custom rounded-0" onClick={() => startVerify()}>
                                                    Continue
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </>
                    :
                        signature === '' ? 
                            <>
                                <div className="hero-custom" style={{backgroundColor: '#EBFDFF', marginTop: '80px', paddingBottom: '80px'}}>
                                    <Container>
                                        <p className="m-0 top-headline" style={{paddingTop: '100px', paddingBottom: '20px'}}>{process.env.REACT_APP_SIMPLE_NAME} On Meta Collection</p>
                                        <h1 className="headline" style={{paddingBottom: '20px'}}>Verify Ownership</h1>

                                        <p className="text-center px-4 my-0 mx-auto fw-bold" style={{maxWidth: '600px'}}>Please verify the ownership of this token by entering your Token ID in the space below.</p>

                                        <p className="text-center px-4 mt-4 mb-0 mx-auto fw-bold" style={{maxWidth: '600px'}}>Verification will be necessary in order to receive any physical pieces you may be entitled to.</p>
                                    </Container>
                                </div>

                                <Container className="py-4">
                                    <Row>
                                        <Col sm={12} md={6} className="d-flex align-items-center justify-content-center p-5 order-1 order-md-0">
                                            <div className="text-center" style={{maxWidth: '100%'}}>
                                                <h2 className="text-start second-headline">Piece Name</h2>
                                                <p className="text-start mb-4" style={{fontSize: '1rem', maxWidth: '600px', overflowWrap: 'break-word'}}>{metadata.name}</p>

                                                <h2 className="text-start second-headline">Metadata Hash</h2>
                                                <p className="text-start mb-4" style={{fontSize: '1rem', maxWidth: '600px', overflowWrap: 'break-word'}}>{Web3.utils.soliditySha3(JSON.stringify(metadata))}</p>

                                                <h2 className="text-start second-headline">Data ID</h2>
                                                <p className="text-start mb-4" style={{fontSize: '1rem', maxWidth: '600px', overflowWrap: 'break-word'}}>{documentId}</p>

                                                <h2 className={`text-start second-headline ${owner !== '' && owner === config.address ? 'text-success' : 'text-danger'}`}>
                                                    {
                                                        owner !== '' && owner === config.address ? 'You are the owner.' : 'You are not the owner.'
                                                    }
                                                </h2>

                                                <div className="mt-4 text-start">
                                                    <Button className="my-2 me-2 btn-custom rounded-0" onClick={() => setOwner('')}>
                                                        Go Back
                                                    </Button>
                                                    <Button className="my-2 me-2 btn-custom rounded-0" onClick={() => startFileSign()}>
                                                        Sign and Continue
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6} className="d-flex align-items-center justify-content-center pb-0 pb-md-5 pt-5 px-5 order-0 order-md-1">
                                            <LazyLoadImage effect="opacity" alt={metadata.name} src={metadata.image} style={{maxWidth: '100%'}} />
                                        </Col>
                                    </Row>
                                </Container>
                            </>
                        :
                            <>
                                <div className="hero-custom" style={{backgroundColor: '#EBFDFF', marginTop: '80px', paddingBottom: '80px'}}>
                                    <Container>
                                        <p className="m-0 top-headline" style={{paddingTop: '100px', paddingBottom: '20px'}}>{process.env.REACT_APP_SIMPLE_NAME} On Meta Collection</p>
                                        <h1 className="headline" style={{paddingBottom: '20px'}}>Verify Ownership</h1>

                                        <p className="text-center px-4 my-0 mx-auto fw-bold" style={{maxWidth: '600px'}}>Please verify the ownership of this token by entering your Token ID in the space below.</p>

                                        <p className="text-center px-4 mt-4 mb-0 mx-auto fw-bold" style={{maxWidth: '600px'}}>Verification will be necessary in order to receive any physical pieces you may be entitled to.</p>
                                    </Container>
                                </div>

                                <Container className="py-4">
                                    <Row>
                                        <Col sm={12} md={6} className="d-flex align-items-center justify-content-center p-5 order-1 order-md-0">
                                            <div className="text-center" style={{maxWidth: '100%'}}>
                                                <h2 className="text-start second-headline">Piece Name</h2>
                                                <p className="text-start mb-4" style={{fontSize: '1rem', maxWidth: '600px', overflowWrap: 'break-word'}}>{metadata.name}</p>

                                                <h2 className={`text-start second-headline ${owner !== '' && owner === config.address ? 'text-success' : 'text-danger'}`}>{ owner !== '' && owner === config.address ? 'Your ownership has been successfully verified!' : 'Your ownership verification has failed.' }</h2>

                                                <div className="mt-4 text-start">
                                                    <Button className={`my-2 me-2 btn-custom rounded-0`} onClick={() => navigate('/my-tokens')}>
                                                        View My Tokens
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6} className="d-flex align-items-center justify-content-center pb-0 pb-md-5 pt-5 px-5 order-0 order-md-1">
                                            <LazyLoadImage effect="opacity" alt={metadata.name} src={metadata.image} style={{maxWidth: '100%'}} />
                                        </Col>
                                    </Row>
                                </Container>
                            </>
            }
        </div>
    );
}
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import NavDropdown from 'react-bootstrap/NavDropdown';
import supportedChains from '../helpers/chains';
import Logo from '../images/metacollection_logo_white.png';


export default function LinkNav({ address, resetApp, chainId }) {
    const [chainName, setChainName] = useState('');

    useEffect(() => {
        if (chainId > -1) {
            const index = supportedChains.map((c) => c.chain_id).indexOf(chainId);
            if (index > -1) {
                setChainName(supportedChains[index].name)
            }
        }
    }, [chainId]);

    return (
        <Navbar fixed="top" expand="lg" className="my-0 py-0" style={{paddingLeft: '15px', paddingRight: '15px', background: 'rgba(63, 63, 63, 0.95)'}}>
            <Container className="p-0" fluid>
                <Navbar.Brand style={{minHeight: '80px', display: 'flex', alignItems: 'center', padding: '0 25px'}}>
                    <a href="https://metacollect.io">
                        <img alt="Meta Collection" src={Logo} width="200px" />
                    </a>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand`} className="border-0 text-white" style={{paddingRight: '25px'}}>
                    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" style={{fill: '#ffffff'}}>
                        <rect y="9" width="20" height="2"></rect>
                        <rect y="3" width="20" height="2"></rect>
                        <rect y="15" width="20" height="2"></rect>
                    </svg>
                </Navbar.Toggle>
                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand`}
                    aria-labelledby={`offcanvasNavbarLabel-expand`}
                    placement="end"
                >
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1">
                            <Link to="/" className="mx-3 nav-link">
                                Mint
                            </Link>
                            <Link to="/my-tokens" className="mx-3 nav-link">
                                My Tokens
                            </Link>
                            <Link to="/verify" className="mx-3 nav-link">
                                Verify
                            </Link>
                            <NavDropdown
                                title="Artists"
                                id={`offcanvasNavbarDropdown-expand-one`}
                                className="mx-3"
                                renderMenuOnMount={true}
                                align="end"
                            >
                                {
                                    JSON.parse(process.env.REACT_APP_MENU_OPTIONS).map((o, i) => (
                                        <NavDropdown.Item target="_blank" rel="noreferrer" key={`navbardropdown-${i}`} href={o[1]}>{o[0]}</NavDropdown.Item>
                                    ))
                                }
                            </NavDropdown>
                            <NavDropdown
                                title="Account"
                                id={`offcanvasNavbarDropdown-expand-two`}
                                className="mx-3"
                                renderMenuOnMount={true}
                                align="end"
                            >
                                <p className="dropdown-pl">Wallet Address</p>
                                <p className="dropdown-p">{address !== '' ? address : 'Not connected'}</p>
                                <p className="dropdown-pl">Network</p>
                                <p className="dropdown-p">{chainName}</p>
                                <p className="dropdown-pl">Contract</p>
                                <p className="dropdown-p">{process.env.REACT_APP_CONTRACT_ADDRESS}</p>
                            </NavDropdown>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    );
}

import React, { createContext, useState, useCallback, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Card from 'react-bootstrap/Card';

import { auth } from "../helpers/base";
import Logo from '../images/metacollection_logo_white.png';


export const AuthContext = createContext({
    currentUser: null,
    setCurrentUser: () => {},
    pending: true,
    setPending: () => {}
});

export const AuthProvider = ({ children }) => {
    const [currentUser, _setCurrentUser] = useState(null);
    const [pending, _setPending] = useState(true);

    const setCurrentUser = useCallback((user) => _setCurrentUser(user), [_setCurrentUser]);
    const setPending = useCallback((p) => _setPending(p), [_setPending]);

    useEffect(() => {
        console.log('loading auth provider');
        auth.onAuthStateChanged((user) => {
            console.log('on auth state changes', user);
            setCurrentUser(user);
            setPending(false);
        });
    });

    return (
        <AuthContext.Provider value={{ currentUser, setCurrentUser, pending, setPending }}>
            {
                pending ? 
                    <Container className="mt-5">
                        <Card className="shadow rounded-0 p-4 mx-auto" style={{background: 'rgba(63, 63, 63, 0.95)', marginTop: '100px', maxWidth: '600px'}}>
                            <Card.Body>
                                <div className="mb-4">
                                    <img alt="Meta Collection" src={Logo} width="200px" />
                                </div>
                                <Spinner className="text-white" animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </Card.Body>
                        </Card>
                    </Container>
                : 
                    children
            }
        </AuthContext.Provider>
    );
};

import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import axios from 'axios';
import { Link } from 'react-router-dom';

import { getPastEvents } from '../helpers/web3';
import ErrorGate from './ErrorGate';


export default function LoadingCard({ transactionHash, web3, signature, chainId }) {
    const [receipt, setReceipt] = useState({});
    const [confirmations, setConfirmations] = useState(0);
    const [values, setValues] = useState({});
    const [error, setError] = useState(null);

    useEffect(() => {
        let timeout = setInterval(async () => {
            try {
                console.log('three seconds', transactionHash);
                if (transactionHash !== '' && transactionHash !== null) {
                    const transactionReceipt = await web3.eth.getTransactionReceipt(transactionHash);
                    const transactionBlockNumber = await web3.eth.getTransaction(transactionHash);
                    const currentBlockNumber = await web3.eth.getBlockNumber();

                    if (transactionReceipt !== null) {
                        setReceipt(transactionReceipt);
                    }
                    
                    if (transactionReceipt !== null && transactionBlockNumber.blockNumber !== null) {
                        const _confirmations = currentBlockNumber - transactionBlockNumber.blockNumber;
                        setConfirmations(_confirmations);

                        console.log('confirmations tx:', transactionReceipt, currentBlockNumber, transactionBlockNumber.blockNumber, _confirmations);

                        if (_confirmations >= 2) {
                            clearInterval(timeout);

                            const events = await getPastEvents(signature, transactionBlockNumber.blockNumber, chainId, web3);
                            setValues(events[0].returnValues);
                            console.log('Contract Events', events[0].returnValues, events);
                        }
                    }
                }
                else {
                    console.log('Oh no!');
                }
            }
            catch(e) {
                clearInterval(timeout);
                console.log('Oh no!', e);
                setError(e);
            }
        }, 3000);

        return () => {
            console.log('unmounted');
            clearInterval(timeout);
        };
    }, [chainId, signature, transactionHash, web3]);

    useEffect(() => {
        const queue = async () => {
            try {
                if (transactionHash !== '' && transactionHash !== null) {
                    const metadataResult = await axios.post('/api/metadata/queue', { signature, transactionHash });
                    console.log('Metadata result', metadataResult.data);
                }
            }
            catch (e) {
                console.log('Error', e);
            }
        };
        queue();
    }, [transactionHash, signature]);

    return (
        <div style={{marginTop: '80px'}}>
            {
                error !== null ? 
                    <ErrorGate />
                :
                    <div>
                        <div className="hero-custom" style={{backgroundColor: '#EBFDFF', marginTop: '80px', paddingBottom: '80px'}}>
                            <Container>
                                <p className="m-0 top-headline" style={{paddingTop: '100px', paddingBottom: '20px'}}>{process.env.REACT_APP_SIMPLE_NAME} On Meta Collection</p>
                                <h1 className="headline" style={{paddingBottom: '20px'}}>Transaction Progress</h1>
                                <p className="my-0 mx-auto px-4 fw-bold" style={{maxWidth: '600px'}}>Thank you for minting. Your transaction is being processed. Once confirmed, you will officially be a token holder.</p>
                            </Container>
                        </div>

                        <Container className="py-4">
                            <Row className="justify-content-center">
                                <Col className="d-flex align-items-center justify-content-center p-5 order-1 order-md-0">
                                    <div className="text-center" style={{maxWidth: '100%'}}>
                                        <h2 className="text-start second-headline">Transaction Hash</h2>
                                        <p className="text-start mb-4" style={{fontSize: '1rem', overflowWrap: 'break-word'}}>{transactionHash}</p>

                                        <h2 className="text-start second-headline">Receipt</h2>
                                        <p className="mb-4 text-start" style={{fontSize: '1rem', maxWidth: '600px', overflowWrap: 'break-word'}}>
                                            {
                                                Object.keys(receipt).length > 0 ? 'Done!' : 
                                                    <>
                                                        Pending...
                                                        <Spinner animation="border" role="status" size="sm" className="ms-3">
                                                            <span className="visually-hidden">Loading.. .</span>
                                                        </Spinner>
                                                    </>
                                            }
                                        </p>

                                        <h2 className="text-start second-headline">Confirmation #1</h2>
                                        <p className="mb-4 text-start" style={{fontSize: '1rem', maxWidth: '600px', overflowWrap: 'break-word'}}>
                                            {
                                                confirmations >= 1 ? 'Done!' : 
                                                    <>
                                                        Pending...
                                                        <Spinner animation="border" role="status" size="sm" className="ms-3">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                    </>
                                            }
                                        </p>

                                        <h2 className="text-start second-headline">Confirmation #2</h2>
                                        <p className="mb-0 text-start" style={{fontSize: '1rem', maxWidth: '600px', overflowWrap: 'break-word'}}>
                                            {
                                                confirmations >= 2 ? 'Done!' : 
                                                    <>
                                                        Pending...
                                                        <Spinner animation="border" role="status" size="sm" className="ms-3">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                    </>
                                            }
                                        </p>

                                        <div className="mt-4 text-start">
                                            <a className="btn btn-custom rounded-0 my-2 me-2" href={`${process.env.REACT_APP_EXPLORER_URL}tx/${transactionHash}`} target="_blank" rel="noreferrer">View On Block Explorer</a>
                                            {
                                                confirmations >= 2 && Object.keys(values).length > 0 && (
                                                    <>
                                                        <a href={`${process.env.REACT_APP_OPENSEA_URL}${process.env.REACT_APP_CONTRACT_ADDRESS}/${values._tokenId}`} target="_blank" rel="noreferrer" className="btn btn-custom rounded-0 my-2 me-2">View on OpenSea</a>
                                                        <Link to="/my-tokens" className="btn btn-custom rounded-0 my-2 me-2">View My Tokens</Link>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </Col>
                                {/* <Col md={12} lg={6} className="d-flex align-items-center justify-content-center pb-0 pb-md-5 pt-5 px-5 order-0 order-md-1">
                                    <img alt={document.name} src={document.image} style={{maxWidth: '100%'}} />
                                </Col> */}
                            </Row>
                        </Container>
                    </div>
            }
        </div>
    );
}

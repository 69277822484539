import abi from './abi.json';

export const CONTRACT = {
    1: {
        address: process.env.REACT_APP_CONTRACT_ADDRESS,
        abi: abi
    },
    4: {
        address: process.env.REACT_APP_CONTRACT_ADDRESS,
        abi: abi
    }
};

import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';


export default function ProcessingCard() {
    return (
        <Container className="my-5" style={{paddingTop: '100px'}}>
            <Card className="p-4 mx-auto shadow rounded-0" style={{maxWidth: '600px', background: 'rgba(63, 63, 63, 0.95)'}}>
                <Card.Body>
                    <h5 className="text-white second-headline second-headline-white mb-4 fs-3">Transaction Processing...</h5>
                    <div>
                        <Spinner animation="border" role="status" className="text-white">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                </Card.Body>
            </Card>
        </Container>
    );
}

import React from 'react';
import Web3 from 'web3';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import 'react-lazy-load-image-component/src/effects/opacity.css';


export default function SigningCard({ mintPrice, goBack, onClick, document, documentId }) {
    return (
        <div style={{marginTop: '80px'}}>
            <div className="hero-custom" style={{backgroundColor: '#EBFDFF', marginTop: '80px', paddingBottom: '80px'}}>
                <Container>
                    <p className="m-0 top-headline" style={{paddingTop: '100px', paddingBottom: '20px'}}>{process.env.REACT_APP_SIMPLE_NAME} On Meta Collection</p>
                    <h1 className="headline" style={{paddingBottom: '20px'}}>Verify Mint</h1>
                    <p className="text-center px-4 my-0 mx-auto" style={{ maxWidth: '600px'}}>You are being asked to sign the following data fields described below. This signature represents your intent to purchase, mint, and own this work. Once signed, this data will be used to verify your ownership and prove that you are the rightful receipient of the work's physical and digital assets. The signature conforms to the EIP-712 standard.</p>

                    <p className="text-center px-4 mt-4 mb-0 mx-auto fw-bold" style={{maxWidth: '600px'}}>Please do not close this window. Your progress will not be saved.</p>

                    <p className="text-center px-4 mt-4 mb-0 mx-auto fw-bold" style={{maxWidth: '600px'}}>Continue below to create your minting signature.</p>
                </Container>
            </div>

            <Container className="py-4">
                <Row>
                    <Col sm={12} md={6} className="d-flex align-items-center justify-content-center p-5 order-1 order-md-0">
                        <div className="text-center" style={{maxWidth: '100%'}}>
                            <h2 className="text-start second-headline">Piece Name</h2>
                            <p className="text-start mb-4" style={{fontSize: '1rem', maxWidth: '600px', overflowWrap: 'break-word'}}>{document.name}</p>

                            <h2 className="text-start second-headline">Metadata Hash</h2>
                            <p className="text-start mb-4" style={{fontSize: '1rem', maxWidth: '600px', overflowWrap: 'break-word'}}>{Web3.utils.soliditySha3(JSON.stringify(document))}</p>

                            <h2 className="text-start second-headline">Data ID</h2>
                            <p className="text-start mb-0" style={{fontSize: '1rem', maxWidth: '600px', overflowWrap: 'break-word'}}>{documentId}</p>

                            <div className="mt-4 text-start">
                                <Button className="my-2 me-2 btn-custom rounded-0" onClick={() => goBack()}>
                                    Go Back
                                </Button>
                                <Button className="my-2 me-2 btn-custom rounded-0" onClick={() => onClick()}>
                                    Sign and Continue
                                </Button>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={6} className="d-flex align-items-center justify-content-center pb-0 pb-md-5 pt-5 px-5 order-0 order-md-1">
                        <LazyLoadImage effect="opacity" alt={document.name} src={document.image} style={{maxWidth: '100%'}} />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

import React,{ useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Web3 from 'web3';
import { doc, getDoc } from 'firebase/firestore';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { db } from '../helpers/base';
import { maxSupplyOfDataId, mintPriceOfDataId, totalTokensOfData } from '../helpers/web3';

import 'react-lazy-load-image-component/src/effects/opacity.css';


export default function TokenTemplateCard({ id, chainId, web3, startSendTransaction, showBorder }) {
    const [_maxSupply, setMaxSupply] = useState(0);
    const [_totalTokens, setTotalTokens] = useState(0);
    const [_mintPrice, setMintPrice] = useState('0');
    const [name, setName] = useState('');
    const [image, setImage] = useState('');
    const [description, setDescription] = useState('');
    const [template, setTemplate] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const _fetch = async () => {
            if (id !== '') {
                setIsLoading(true);
                const docRef = doc(db, "templatesData", id);
                const docSnap = await getDoc(docRef);
                console.log('doc snap', docSnap, docSnap.exists());
    
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    console.log("Document data:", data);
                    setMintPrice(data.priceInWei);
                    setImage(data.image);
                    setDescription(data.description);
                    setName(data.name);
                }
                else {
                    console.log("No template data document!");
                }

                const templateRef = doc(db, "templates", id);
                const templateSnap = await getDoc(templateRef);
    
                if (templateSnap.exists()) {
                    const data = templateSnap.data();
                    setTemplate(data);
                    console.log('Template data:', data);
                }
                else {
                    console.log("No template document!");
                }

                const __totalTokens = await totalTokensOfData(id, chainId, web3);
                setTotalTokens(parseInt(__totalTokens));

                const __maxSupply = await maxSupplyOfDataId(id, chainId, web3);
                setMaxSupply(parseInt(__maxSupply));

                const __mintPrice = await mintPriceOfDataId(id, chainId, web3);
                setMintPrice(__mintPrice);

                setIsLoading(false);
            }
        };
        _fetch();
    }, [id, chainId, web3]);

    return (
        <>
            {
                isLoading ? 
                    <div className="py-4">
                        <Spinner animation="border" role="status" size="sm">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                :
                    <div className={`${showBorder ? 'border-bottom border-dark': ''} py-4 mb-5`}>
                        <Row>
                            <Col md={12} lg={6} className="d-flex align-items-center justify-content-center p-5 order-1 order-md-0">
                                <div className="text-center">
                                    <h2 className="text-start second-headline">{name}</h2>
                                    <p className="text-start" style={{fontSize: '1rem', maxWidth: '600px'}}>{description}</p>
                                    <h6 className="text-start my-4 subline" style={{fontWeight: 'bold'}}>Available: {_maxSupply - _totalTokens}</h6>
                                    <h6 className="text-start my-4 subline" style={{fontWeight: 'bold'}}>Identifier: {id}</h6>
                                    <h6 className="text-start mt-4 mb-0 subline" style={{fontWeight: 'bold'}}>Price: {Web3.utils.fromWei(_mintPrice, 'ether')} ETH + GAS</h6>

                                    <div className="mt-4 text-start">
                                        <Button disabled={_totalTokens >= _maxSupply ? true : false} onClick={() => startSendTransaction(id, template, name, _mintPrice)} className="btn-custom rounded-0">
                                            {
                                                _totalTokens >= _maxSupply ? 'Sold Out' : 'Mint'
                                            }
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col md={12} lg={6} className="d-flex align-items-center justify-content-center pb-0 pb-md-5 pt-5 px-5 order-0 order-md-1">
                                <LazyLoadImage alt={name} src={image} effect="opacity" style={{maxWidth: '100%'}} />
                            </Col>
                        </Row>
                    </div>
            }
        </>
    );
}

import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import { refreshPage } from '../helpers/utilities';


export default function ErrorGate() {
    return (
        <Container className="my-5" style={{marginTop: '80px'}}>
            <p className="m-0 top-headline" style={{paddingTop: '100px', paddingBottom: '20px'}}>{process.env.REACT_APP_SIMPLE_NAME} On Meta Collection</p>
            <h1 className="headline" style={{paddingBottom: '20px'}}>Oh No! There was an error.</h1>
            <Button className="mt-0 btn-custom rounded-0" onClick={() => refreshPage()}>Refresh page</Button>
        </Container>
    );
}
